<template>
    <section class="results-list map-overlay" v-bind:class="{ open: isOpen }">

        <header class="map-overlay-header">
            <button class="map-overlay-header__button" @click="isOpen = !isOpen">
                <svg class="icon map-overlay-header__icon" aria-hidden="true">
                    <use xlink:href="#icon-list"></use>
                </svg>
            </button>
            <h2 class="map-overlay-header__title" v-if="!isShowDetail">Deine Suche</h2>
            <button @click="hideDetail()" class="map-overlay-header__back" v-bind:class="{ open: isShowDetail }">
                <div class="btn-arrow">
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-chevron-left"></use>
                    </svg>
                </div>
                <span>Zurück zur Übersicht</span>
            </button>
        </header>
        <button
            @click="isOpen = !isOpen" class="map-overlay__toggle btn-arrow">
            <span class="sr-only">Schließen</span>
            <svg v-if="$breakpoint.smAndDown" class="icon icon-close" aria-hidden="true">
                <use xlink:href="#icon-close"></use>
            </svg>
            <svg v-else class="icon icon-chevron-right" aria-hidden="true">
                <use xlink:href="#icon-chevron-right"></use>
            </svg>
        </button>

        <ul class="map-overlay-body results-list__list">
            <li
                v-for="(store, index) in storesList"
                v-bind:key="index"
                v-if="store.active !== false"
                @click="showDetail(store.id)"
                class="results-list__item result">
                <div class="result__icon">
                    <svg class="icon" :class="'icon--' + categories[store.category].icon">
                        <span class="sr-only">{{ categories[store.category].label }}</span>
                        <use v-bind:xlink:href="'#icon-' + categories[store.category].icon"></use>
                    </svg>
                </div>
                <div class="result__main">
                    <h3 class="result__name">{{ store.name }}</h3>
                    <p class="result__address">{{ store.address.street }} <br>{{ store.address.postcode }} {{ store.address.city }}</p>
                </div>
                <div class="result__meta">
                    <span class="result__distance">
                        {{ store.dist }} km
                    </span>
                    <a class="result__more-link" href="#">
                        Details
                    </a>
                </div>
            </li>
        </ul>

        <div class="map-overlay-body results-list__detail" v-bind:class="{ open: isShowDetail &&
          isOpen }">
            <div v-for="store in stores" :key="store.id">
                <div v-if="store.id == detailId">
                    <div class="result__icon">
                        <svg class="icon" :class="'icon--' + categories[store.category].icon" aria-hidden="true">
                            <use v-bind:xlink:href="'#icon-' + categories[store.category].icon"></use>
                        </svg>
                    </div>
                    <h2>{{ store.name }}</h2>
                    <p class="result__address">{{ store.address.street }} <br>{{ store.address.postcode }} {{ store.address.city }}</p>
                    <div class="spread">
                        <p>Entfernung: {{ store.dist }} km</p>
                        <a class="link-with-icon" :href="'https://www.google.de/maps/dir/?api=1&destination=' + store.coords[0] + ',' + store.coords[1]" target="_blank">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-map-signs"></use>
                            </svg>
                            Routenplanung
                        </a>
                    </div>
                    <h3 v-if="store.openingTimes.length > 0">Öffnungszeiten</h3>
                    <table>
                        <tr v-for="(opening, index) in store.openingTimes" :key="index" >
                            <th><span>{{ opening.name }}</span></th>
                            <td><span v-for="(times, index) in opening.times" :key="index" >{{ times }} <br></span></td>
                        </tr>
                    </table>

                    <h3>Sorten & Gebinde</h3>

                    <template v-for="productCategory in getStoreProducts(store.products)"
                        v-if="productCategory">
                      <div class="product-category">
                        <h4>{{ productCategory.name }}</h4>
                      <ul class="products">
                         <li v-for="product in productCategory.products" :key="product.id">
                            <div class="product__img">
                                <img v-if="product.image" :src="`${product.image}`" alt="">
                            </div>
                            <p>{{ product.name }}</p>
                        </li>
                      </ul>
                      </div>
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import breakpoint from '../mixins/breakpoint';

export default {
    name: 'ResultsList',
    mixins: [breakpoint],
    props: {
        categories: Object,
        products: Object,
        stores: Object,
    },
    data() {
        return {
            isOpen: false,
            detailId: 0,
            isShowDetail: false,
            storesList: null
        };
    },
    watch: {
        isOpen() {
            if (this.isOpen) this.$emit('close-overlays');
        },
        stores() {
            this.storesList = Object.values(this.stores);
            this.storesList.sort(function(a, b){
              if (a.dist && b.dist && Number.parseFloat(a.dist.replace(',', '.')) < Number.parseFloat(b.dist.replace(',', '.'))) {
                return -1;
              } else if (a.dist && b.dist && Number.parseFloat(a.dist.replace(',', '.')) > Number.parseFloat(b.dist.replace(',', '.'))) {
                return 1;
              } else {
                return 0;
              }
            });
        }
    },
  methods: {
    showDetail(id) {
      this.isOpen = true;
      this.detailId = id;
      this.isShowDetail = true;
      this.$emit('set-marker-selected', id);
    },
    hideDetail() {
      this.isShowDetail = false;
      this.$emit('remove-marker-selected');
    },
    doClose() {
      this.isOpen = false;
    },
    getStoreProducts(ids) {
      let storeProducts = [];
      const productsArray = Object.keys(this.products).map(i => this.products[i]);

      for (let j = 0; j < productsArray.length; j += 1) {
          var cat = {
            name: productsArray[j].name,
            id: productsArray[j].id,
            products: []
          };

        const productsGroupArray = Object.keys(productsArray[j].products).map(i => productsArray[j].products[i]);
        for (let k = 0; k < productsGroupArray.length; k += 1) {
          if (ids.includes(productsGroupArray[k].id)) {
            cat.products.push(productsGroupArray[k]);
          }
        }
        if (cat.products.length > 0) {
          storeProducts[j] = cat;
        }
      }
      return storeProducts;
    },
  },
};
</script>

<style scoped lang="scss">


.result {
  border-bottom: 1px solid $gray-light;
  cursor: pointer;
  display: flex;
  padding: .5rem;
  width: 100%;

  @include screen('sm-min'){
    padding: 1rem .75rem;
  }

  &:hover {
    background-color: #F0F2DD;
  }

  &:last-child {
    border-bottom: 0;
  }
}

.result__more-link {
  font-family: $headings-font-family;
}

.result__icon {
  font-size: 1.5rem;
  margin-right: .8rem;
  width: 2rem;
}

.result__main {
  flex: 5;
}

.result__meta {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.result__address {
  margin: 0;
  margin-bottom: .8rem;
}

.result__distance {
  white-space: nowrap;
}

.results-list.open {
  @include screen('sm-max') {
    transform: translateY(-48px);
  }
}

.results-list__list {
  border: 1px solid $gray-light;
}

.results-list__detail {
  padding-top: 1rem;
  padding-left: 2.2rem;
  overflow-x: hidden;

  @include screen('sm-min') {
    padding-left: 2.75em;
  }

  .open & {
    transform: translateX(-40px);
    opacity: 0;
    pointer-events: none;
  }

  &.open {
    opacity: 1;
    transform: translateX(0);
    pointer-events: initial;
  }

  .result__icon {
    position: absolute;
    top: .8rem;
    left: 0;

    @include screen('sm-min'){
      left: .2rem;
      top: .9rem;
    }
  }

  .spread {
    width: 97%;
  }

  .product-category {
    margin-top: 15px;
    margin-bottom: 10px;
    h4 {
      font-size: $font-size-base;
      margin-bottom: 10px;

    }
  }

  .products {
    @include screen('sm-min'){
      display: flex;
      flex-wrap: wrap;

      li {
        transition: outline .3s;
        position: relative;

        p {
          background: $brand-secondary;
          color: #fff;
          padding: 19px;
          width: 224px;
          display: block;
          position: absolute;
          bottom: 100%;
          left: 5px;
          z-index: 10;
          margin-bottom: 14px;
          display: none;

          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 100%;
            left: 20px;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-top: 14px solid $brand-secondary;
          }
        }

        &:nth-child(4n + 3),
        &:nth-child(4n + 4) {
          p {
            left: auto;
            right: 5px;

            &:after {
              left: auto;
              right: 20px;
            }
          }
        }

        &:hover {
          .product__img {
            outline: 2px solid $gray-light;
          }

          p {
            display: block;
          }
        }
      }
    }

    p {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 0;

      @include screen('sm-max'){
        max-width: calc(100% - 88px);
      }
    }
  }

  .product__img {
    width: 76px;
    height: 76px;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid $gray-light;
    margin-right: 12px;
    margin-bottom: 5px;
    position: relative;

    @include screen('sm-min'){
      margin-right: 5px;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-height: 68px;
    }
  }
}

</style>
