<template>

    <section class="map-layer">
        <div id="map"></div>
    </section>
</template>

<script>
/* eslint no-underscore-dangle: ["error", { "allow": ["_geocodeResult", "_icon"] }] */
/* eslint-disable no-restricted-syntax */
import 'leaflet/dist/leaflet.css';

import L from 'leaflet';
/* eslint-disable-next-line */
import Control from 'leaflet-control-geocoder';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import {getJSON} from '@/utils.js';

/* eslint-disable-next-line */
import MarkerClusterGroup from 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

if (!('remove' in Element.prototype)) {
    Element.prototype.remove = function() {
        if (this.parentNode) {
            this.parentNode.removeChild(this);
        }
    };
}

export default {
    name: 'Map',
    props: {
        searchRadius: Number,
        stores: Object,
        products: Object,
        location: Array,
        categories: Object,
    },
    data: () => ({
        publicPath: process.env.BASE_URL,
        internalLocation: [],
        map: null,
        geocoder: null,
        geocoderControl: null,
        tileLayer: null,
        results: null,
        locationCircle: null,
    }),
    watch: {
        stores() {
            this.updateLayers();
            this.filter();
        },
        location() {
            this.internalLocation = this.location;
        },
    },
    created() {
        this.internalLocation = this.location;
    },
    mounted() {
        this.initMap();
    },
    methods: {
        initMap() {
            const self = this;

            this.markerClusters = L.markerClusterGroup({
              animateAddingMarkers: true,
	            showCoverageOnHover: false,
            });
            this.map = L.map('map').setView(this.internalLocation, 12);
            this.map.zoomControl.setPosition('bottomright');

            var customGeocoder = L.Control.Geocoder.nominatim({
                geocodingQueryParams: {
                    countrycodes: 'de',
                },
            });

          customGeocoder.geocode = function(query, cb, context) {
            getJSON (
              this.options.serviceUrl + 'search',
              L.extend(
                {
                  q: query,
                  limit: 5,
                  format: 'json',
                  addressdetails: 1
                },
                this.options.geocodingQueryParams
              ),
              L.bind(function(data) {
                var results = [];
                for (var i = data.length - 1; i >= 0; i--) {
                  var bbox = data[i].boundingbox;
                  for (var j = 0; j < 4; j++) bbox[j] = parseFloat(bbox[j]);
                  results[i] = {
                    icon: data[i].icon,
                    name: data[i].display_name,
                    html: this.options.htmlTemplate ? this.options.htmlTemplate(data[i]) : undefined,
                    bbox: L.latLngBounds([bbox[0], bbox[2]], [bbox[1], bbox[3]]),
                    center: L.latLng(data[i].lat, data[i].lon),
                    properties: data[i]
                  };
                }
                cb.call(context, results);
              }, this)
            );
          };

          this.geocoder = customGeocoder;
          this.geocoderControl = L.Control.geocoder({
            geocoder: customGeocoder,
            collapsed: false,
            expand: 'touch',
            zoomSnap: 1,
            defaultMarkGeocode: false,
            placeholder: 'Ort oder Postleitzahl eingeben',
            position: 'topleft',
            errorMessage: 'Nichts gefunden',
          }).on('markgeocode', (e) => {
            if (self.locationCircle) { self.map.removeLayer(self.locationCircle); }
            self.locationCircle = L.circle(e.geocode.center, { radius: self.searchRadius }).addTo(self.map);
            self.map.fitBounds(self.locationCircle.getBounds());
            self.internalLocation = [e.geocode.center.lat, e.geocode.center.lng];
            self.$emit('set-location', self.internalLocation);
            self.updatePosition();
            Array.prototype.forEach.call(document.querySelectorAll('.leaflet-control-geocoder-alternatives li'), function( node ) {
              node.remove();
            });
          }).addTo(this.map);

          this.tileLayer = L.tileLayer(
            'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}@2x.png',
            {
              maxZoom: 16,
              attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>',
            },
          );

          this.tileLayer.addTo(this.map);
        },
      updateLayers() {
        this.markerClusters.clearLayers();
        this.map.removeLayer(this.markerClusters);

        // Get all stores and add to map if the category is active
        const icons = [];
        const iconImage = ['marker-red.png', 'marker-green.png', 'marker-blue.png'];

        for (let i = 0; i < iconImage.length; i += 1) {
          const currentIcon = new L.Icon({
            iconUrl: `${this.publicPath}/assets/images/${iconImage[i]}`,
            shadowUrl: `${this.publicPath}/assets/images/marker-shadow.png`,
            iconSize: [28, 37],
            iconAnchor: [15, 37],
            popupAnchor: [0, -32],
            shadowSize: [37, 37],
          });

          icons.push(currentIcon);
        }

        const currentPos = L.latLng(this.internalLocation);
        const self = this;

        for (const store in this.stores) {
          if (Object.prototype.hasOwnProperty.call(this.stores, store)) {
            this.stores[store].dist = ((((currentPos.distanceTo(this.stores[store].coords)).toFixed(0)) / 1000).toFixed(1)).replace('.', ',');
            this.stores[store].marker = L.marker(this.stores[store].coords, { icon: icons[(this.stores[store].category - 1)] });

            if (self.categories[this.stores[store].category].active) {
              this.markerClusters.addLayer(this.stores[store].marker.on('click', () => { self.setMarkerSelected(this.stores[store].id); self.$emit('show-detail', this.stores[store].id); }));
            }
          }
        }

        this.map.addLayer(this.markerClusters);
      },
      setMarkerSelected(id) {
        this.removeMarkerSelected();

        const point = Object.values(this.stores).find(currentPoint => currentPoint.id === id);

        let latLngs = [ point.marker.getLatLng() ];
        let markerBounds = L.latLngBounds(latLngs);
        this.map.fitBounds(markerBounds);


        /* eslint no-underscore-dangle: ["error", { "allow": ["_icon"] }] */
        if (point.marker._icon) {
          point.marker._icon.classList.add('active');
        }
      },
      removeMarkerSelected() {
        const markers = document.querySelectorAll('.leaflet-marker-icon');
        this.map.fitBounds(this.locationCircle.getBounds());
        for (let i = 0; i < markers.length; i += 1) {
          markers[i].classList.remove('active');
        }
      },
      updateGeoSearch(loc) {
        const geoInput = document.querySelector('.leaflet-control-geocoder-form input');

        if (Array.isArray(loc)) {
          this.internalLocation = loc;
          geoInput.value = `${this.internalLocation[0]}, ${this.internalLocation[1]}`;
          this.map.setView(this.internalLocation, 12);
          if (this.locationCircle) { this.map.removeLayer(this.locationCircle); }
          this.locationCircle = L.circle(this.internalLocation, { radius: this.searchRadius }).addTo(this.map);
          this.map.fitBounds(this.locationCircle.getBounds());
          this.updatePosition();
        } else {
          geoInput.value = loc;
          this.triggerGeoSearch();
        }
      },
      triggerGeoSearch() {
        const self = this;

        const geoInput = document.querySelector('.leaflet-control-geocoder-form input');

        this.geocoder.geocode(geoInput.value, (results) => {
          // eslint-disable-next-line
          self.geocoderControl._geocodeResult(results, true);
        });
      },
      updatePosition() {
        this.$emit('close-overlays');

        const currentPos = L.latLng(this.internalLocation);

        for (const store in this.stores) {
          if (Object.prototype.hasOwnProperty.call(this.stores, store)) {
            this.stores[store].dist = ((((currentPos.distanceTo(this.stores[store].coords)).toFixed(0)) / 1000).toFixed(1)).replace('.', ',');
          }
        }
      },
      filter() {
        for (const store in this.stores) {
          if (Object.prototype.hasOwnProperty.call(this.stores, store)) {
            this.stores[store].active = false;
            this.markerClusters.removeLayer(this.stores[store].marker);
            for (const product in this.products) {
              if (Object.prototype.hasOwnProperty.call(this.products, product)) {
                for (const groupProduct in this.products[product].products) {
                  if (Object.prototype.hasOwnProperty.call(this.products[product].products, groupProduct)) {
                    if (this.stores[store].products.includes(this.products[product].products[groupProduct].id)) {
                      if (this.products[product].products[groupProduct].active && this.categories[this.stores[store].category].active) {
                        this.stores[store].active = true;
                        this.markerClusters.addLayer(this.stores[store].marker);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
    },
};

</script>

<style lang="scss" scoped>
.map {
  flex: 1;
  position: relative;
  min-height: 100vh;
  min-width: 100vw;
}

.leaflet-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.filters {
  z-index: 998;
  position: absolute;
  top: 7rem;
  right: 5rem;
  background-color: #fff;
}

.leaflet-control-geosearch.bar {
  margin-left: 0;
}

</style>
