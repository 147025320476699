<template>
    <section class="app-header">
        <div class="app-header__search">
            <svg v-if="$breakpoint.lgAndUp" class="icon app-header__icon" aria-hidden="true">
                <use xlink:href="#icon-map-marker"></use>
            </svg>
            <div class="search-box">
                <form class="search-box__form search-form">
                    <label for="location" class="sr-only">Wo möchten Sie suchen?</label>
                    <div class="search-form__group">
                        <input class="search-form__input"
                            type="text"
                            id="location"
                            placeholder="Ort oder Postleitzahl eingeben"
                            v-model="currentLocation" />

                        <div class="search-form__submit button">
                            <span class="sr-only">Suchen</span>
                            <svg v-if="isLoading" class="icon search-form__loader" aria-hidden="true">
                                <use xlink:href="#icon-spinner"></use>
                            </svg>
                            <svg v-else class="icon" aria-hidden="true">
                                <use xlink:href="#icon-search"></use>
                            </svg>
                        </div>
                    </div>
                </form>

                <button class="search-geo button button--ghost" @click="updateLocation()">
                    <span class="sr-only">
                        In der Nähe meines Standorts suchen
                    </span>
                    <svg class="search-geo__icon icon" aria-hidden="true">
                        <use xlink:href="#icon-pin"></use>
                    </svg>
                </button>
            </div>

        </div>
        <div class="app-header__branding">
            <h1 class="app-header__title">Der Rothaus Zäpflefinder</h1>
            <img v-if="$breakpoint.smAndDown" class="app-header__logo" alt="Tannenzäpfle" src="@/assets/images/cones.svg" width="56" height="38"/>
            <img v-else class="app-header__logo" alt="Rothaus Logo" src="@/assets/rothaus-logo.svg" />
        </div>
    </section>
</template>

<script>
import breakpoint from '../mixins/breakpoint';

export default {
    name: 'AppHeader',
    mixins: [breakpoint],
    props: {
        location: Array,
    },
    data() {
        return {
            currentLocation: this.location,
            isGeolocationLoaded: false,
            isLoading: false,
        };
    },
    methods: {
        loadGeoLocation() {
            this.isLoading = true;
            const self = this;

            this.$getLocation()
                .then((coordinates) => {
                    self.currentLocation = [coordinates.lat, coordinates.lng];
                    self.isGeolocationLoaded = true;

                    if (self.isLoading) {
                        self.isLoading = false;
                        self.updateLocation();
                    }
                });
        },
        updateLocation() {
            if (this.isGeolocationLoaded) {
                this.$emit('update-location', this.currentLocation);
            } else {
                this.loadGeoLocation();
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.app-header {
    border-top: 8px solid $brand-secondary;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1000;
    background: #fff;
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: .5rem .75rem;

    @include screen('sm-min'){
        align-items: center;
        flex-direction: row;
    }
    @include screen('md-min'){
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.app-header__icon {
    font-size: 1.8rem;
    margin: .7rem 1.1rem 0;
}

.app-header__search {
    align-items: flex-start;
    display: flex;
}

.search-box {
    display: flex;
    width: 100%;
}

.search-form__group {
    display: flex;
    flex-wrap: wrap;
}

.search-form {
    flex: 1;
}

.search-form__input {
    visibility: hidden;
    border-bottom: 3px solid $gray;
    border-left: 1px solid $gray-medium;
    border-right: 1px solid $gray-medium;
    border-top: 1px solid $gray-medium;
    color: $gray;
    flex: 4;
    font-family: $font-family-sans-serif;
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    padding: .5em 1em;
    transition: border-bottom .2s ease-in;
    width: 100%;

    @include screen('xs-min') {
        width: 230px;
        flex-basis: 230px;
    }

    @include screen('md-min') {
        width: 304px;
        flex-basis: 304px;
    }

    &:focus {
        border-bottom-color: $brand-secondary;
        box-shadow: 2px 2px 16px rgba(0, 0, 0, .5);
        color: $brand-secondary-dark;

        &::placeholder {
            color: $brand-secondary;
            opacity: .5;
            transition: all ease-in .2s;
        }
    }
}

.search-form__submit,
.search-geo {
    font-size: $font-size-h3;
    margin-left: .5rem;
    //max-width: 3rem;
    height: 59px;
    width: 60px;

    @include screen('sm-max') {
        height: 44px;
        width: 44px;
    }
}

.app-header__branding {
    align-items: center;
    display: flex;
}

.app-header__title {
    font-size: 1.1rem;
    margin-top: .3rem;
    margin-bottom: .6rem;
    white-space: nowrap;

    @include screen('xs-min') {
        margin-top: 0;
        font-size: 1.5rem;
        margin-bottom: .5rem;
    }
    @include screen('sm-min') {
        margin-bottom: 0;
        text-align: right;
        font-size: 1.4rem;
    }
    @include screen('md-min') {
        font-size: 1.7rem;
    }
    @include screen('lg-min') {
        font-size: 2.2rem;
    }
    @include screen('sm-h-max-md-min') {
        font-size: 1.7rem;
    }
}

.app-header__logo {

    @include screen('sm-min') {
        display: block;
        margin-left: 1rem;
        width: 4.5rem;
    }
    @include screen('md-min') {
        margin-left: 2rem;
    }
    @include screen('sm-h-max') {
        width: 3.5rem;
    }
    @include screen('sm-max') {
        position: absolute;
        right: 15px;
        top: -1px;
    }
}
</style>
