<template>
    <section class="filters map-overlay" v-bind:class="{ open: isOpen, highlight: isHighlight }">

        <header class="map-overlay-header">
            <button class="map-overlay-header__button" @click="isOpen = !isOpen">
                <svg class="icon map-overlay-header__icon" aria-hidden="true">
                    <use xlink:href="#icon-filter"></use>
                </svg>
            </button>
            <h2 class="map-overlay-header__title">Was genau suchst du?</h2>
        </header>
        <button
            @click="isOpen = !isOpen" class="map-overlay__toggle btn-arrow">
            <span class="sr-only">Schließen</span>
            <svg v-if="$breakpoint.smAndDown" class="icon icon-close" aria-hidden="true">
                <use xlink:href="#icon-close"></use>
            </svg>
            <svg v-else class="icon icon-chevron-right" aria-hidden="true">
                <use xlink:href="#icon-chevron-right"></use>
            </svg>
        </button>

        <div class="map-overlay-body">
            <form action="" v-on:submit="filter($event)">
                <div class="map-overlay__content">
                    <table>
                        <tr v-for="category in categories" :key="category.id">
                            <th>
                                <div class="checkbox">
                                    <input :id="'category-' + category.id" type="checkbox" v-model="category.active">
                                    <label :for="'category-' + category.id">{{ category.label }}</label>
                                </div>
                            </th>
                            <td>
                                <svg class="icon" :class="'icon--' + category.icon" aria-hidden="true">
                                    <use v-bind:xlink:href="'#icon-' + category.icon"></use>
                                </svg>
                            </td>
                        </tr>
                    </table>
                    <h2>Welche Sorte?</h2>
                    <div id="toggleCheckbox" class="checkbox">
                        <input id="product-group-toggle-all" v-model="toggleAllBoo" type="checkbox" v-on:change="toggleAll()">
                        <label for="product-group-toggle-all">Alle auswählen</label>
                    </div>
                    <ul class="products">
                        <li v-for="product in products" :key="product.id" >
                            <div v-if="getObjectSize(product.products) > 1" class="checkbox dropdown" :class="{ open: product.dropdownOpen }">
                                <button type="button" class="dropdown__button" @click="product.dropdownOpen = !product.dropdownOpen">
                                    <input :id="'product-group-' + product.id" type="checkbox" v-model="product.active" v-on:change="toggleGroupProducts(product)">
                                    <label :for="'product-group-' + product.id">{{ product.name }}</label>
                                    <svg class="icon dropdown__icon" aria-hidden="true">
                                        <use xlink:href="#icon-chevron-down"></use>
                                    </svg>
                                </button>
                                <ul class="dropdown__content">
                                    <li v-for="groupProduct in product.products" :key="`groupProduct-${groupProduct.id}`">
                                        <input :id="'product-' + groupProduct.id" type="checkbox" v-model="groupProduct.active" v-on:change="toggleGroup(product)">
                                        <label :for="'product-' + groupProduct.id">{{ groupProduct.name }}</label>
                                    </li>
                                </ul>
                            </div>
                            <div v-else class="checkbox">
                                <input :id="'product-' + product.id" type="checkbox" v-model="product.active">
                                <label :for="'product-' + product.id">{{ product.name }}</label>
                            </div>
                        </li>
                    </ul>
                </div>
                <button type="submit" class="button">Filter anwenden</button>
                <button type="button" class="link" @click="resetFilters()">Filter zurücksetzen</button>
            </form>
        </div>
    </section>
</template>

<script>
import breakpoint from '../mixins/breakpoint';

export default {
    name: 'Filters',
    props: {
        location: Array,
        categories: Object,
        products: Object,
    },
    mixins: [breakpoint],
    data() {
        return {
            isHighlight: false,
            isOpen: false,
            toggleAllBoo: true
        };
    },
    watch: {
        isOpen() {
            if (this.isOpen) this.$emit('close-overlays');
        },
        products() {
        }
    },
    methods: {
        doClose() {
            this.isOpen = false;
        },
        doOpen() {
            const self = this;
            this.isHighlight = false;
            this.resetFilters();

            if (this.$breakpoint.mdAndUp) {
                this.isOpen = true;
            } else {
                setTimeout(function() { self.isHighlight = false; }, 3000);
                this.isHighlight = true;
            }
        },
        filter(event) {
            if (event) event.preventDefault();

            this.$emit('filter');

            // if small window, close filter tab
            if (this.$breakpoint.smAndDown) this.doClose();
        },
        resetFilters() {
            this.toggleAllBoo = true;

            const categoriesArray = Object.keys(this.categories).map(i => this.categories[i]);

            for (let i = 0; i < categoriesArray.length; i += 1) {
                categoriesArray[i].active = true;
            }

            for (let key in this.products) {
                this.products[key].active = true;
                if (this.products[key].products) this.toggleGroupProducts(this.products[key]);
            }

            this.filter();
        },
        toggleAll() {
            for (let key in this.products) {
                this.products[key].active = this.toggleAllBoo;
                if (this.products[key].products) this.toggleGroupProducts(this.products[key]);
            }
        },
        toggleGroup(group) {
            let notActiveProducts = 0;
            let activeProducts = 0;
            for (let key in group.products.length) {
                if (group.products[key].active) {
                    activeProducts += 1;
                } else {
                    notActiveProducts += 1;
                }
            }

            if (activeProducts === group.products.length) {
                /* eslint-disable-next-line */
                group.active = true;
            } else if (notActiveProducts === group.products.length) {
                /* eslint-disable-next-line */
                group.active = false;
            }
        },
        toggleGroupProducts(group) {
            for (let key in group.products) {
                /* eslint-disable-next-line */
                group.products[key].active = group.active;
            }
        },
        getObjectSize(myObject) {
            let osize = 0;

            Object.keys(myObject).forEach((key) => {
                if (Object.prototype.hasOwnProperty.call(myObject, key)) osize += 1;
            });
            return osize;
        },
    },
};
</script>

<style scoped lang="scss">
    .products {
        margin-bottom: 1.25rem;

        .checkbox {
            background: $paper-dark;
            border: 1px solid $gray-light;
            padding: .4rem .6rem;
            margin-bottom: rem(5px);
        }
    }

    table {
        .checkbox {
            display: inline-block;
            vertical-align: top;

            label:before {
                background: $paper-dark;
            }
        }

        th, td {
            vertical-align: middle;
        }

        th:after {
            top: 18px;
        }

        td {
            width: 2.75rem;
        }

        .icon {
            font-size: rem(23px);
        }
    }

    .map-overlay-body {
        margin-left: 3.4rem;
        width: calc(100% - 70px);
        overflow: visible;

        @include screen('sm-min') {
            margin-left: 4.4rem;
            width: rem(345px);
        }

        form {
            height: 100%;
        }
    }

    .map-overlay__content {
        overflow-y: auto;
        max-height: calc(100% - 120px);
        margin-bottom: 20px;
    }

    .button {
        font-size: rem(18px);
        width: 100%;
    }

    #toggleCheckbox {
        margin-bottom: 5px;
        margin-left: 11px;

        @include screen('sm-max') {
        margin-bottom: 3px;
            margin-left: 9px;
        }
    }

    .highlight .map-overlay-header__icon {
        animation: highlightIcon 3.3s;
    }

    @keyframes highlightIcon {
        0%, 15% { color: $gray-dark; }
        25% { color: $brand-secondary; }
        45% { color: $gray-dark; }
        65% { color: $brand-secondary; }
        85%, 100% { color: $gray-dark; }
    }
</style>
